import React from 'react';
import './home.css';
import Social from './Social';
import { AiOutlineSend } from 'react-icons/ai';

const Home = () => {
  return (
    <section className='home section' id='home'>
        <div className='home__container container grid'>
            <div className='home__content grid'>
                <Social />
                <div className='home__img'></div>
                <div className='home__data'>
                    <h1 className='home__title'>Jasmine Khalimova</h1>
                    <h3 className='home__subtitle'>— Software Developer —</h3>
                    <p className='home__description'>Experienced software  developer with a passion for developing innovative applications.</p>
                    <a href='#contact' className='button button--flex'>Get In Touch <AiOutlineSend className='send-icon'/></a>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Home